<script lang="ts" setup>
const props = defineProps<{ noTitle?: boolean }>()

const { t } = useI18n()
const globalStore = useGlobalStore()
const currentStore = useCurrentStore()
const { options } = useDeliveryOptions()
const { acceptedPayments } = usePaymentsOffered()

const hasSocialMedia = computed(() => {
  return (
    currentStore.socialNetworkUris?.instagram ||
    currentStore.socialNetworkUris?.facebook ||
    currentStore.socialNetworkUris?.twitter ||
    currentStore.socialNetworkUris?.tiktok ||
    currentStore.socialNetworkUris?.youtube ||
    currentStore.socialNetworkUris?.kwai
  )
})

const salesperson = computed(() => globalStore.store.salesperson)
</script>

<template>
  <div class="about-us flex flex-col gap-4 text-left" v-bind="$attrs">
    <RBaseCard class="!rounded-2xl !p-4 sm:!p-8">
      <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-4">
          <img
            alt=""
            class="m-auto h-20 w-20 rounded-full sm:h-28 sm:w-28"
            :src="optimizedImage(currentStore.profileImageUri || '/images/empty-logo.png', 'thumb')"
          />

          <div
            v-if="currentStore.description"
            class="max-w-[400px] self-center text-center text-sm text-gray-300"
          >
            {{ currentStore.description }}
          </div>
        </div>

        <div class="flex flex-col gap-2 break-words">
          <h2 v-if="!props.noTitle" class="text-xl font-bold text-mine-shaft">
            {{ t('components.aboutUs.sectionOne.title') }}
          </h2>

          <div class="whitespace-break-spaces text-sm font-normal text-gray-300">
            {{ currentStore.aboutUs }}
          </div>
        </div>

        <div v-if="hasSocialMedia" class="flex flex-col gap-3">
          <div class="text-[12px] font-semibold text-mine-shaft">
            {{ t('components.aboutUs.sectionOne.socialTitle') }}
          </div>

          <div data-testid="social-links" class="flex flex-wrap gap-2 sm:gap-3">
            <RSocialButton
              v-if="currentStore.socialNetworkUris?.instagram"
              :link="currentStore.socialNetworkUris?.instagram"
              company="instagram"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />

            <RSocialButton
              v-if="currentStore.socialNetworkUris?.facebook"
              :link="currentStore.socialNetworkUris?.facebook"
              company="facebook"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />

            <RSocialButton
              v-if="currentStore.socialNetworkUris?.twitter"
              :link="currentStore.socialNetworkUris?.twitter"
              company="twitter"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />

            <RSocialButton
              v-if="currentStore.socialNetworkUris?.tiktok"
              :link="currentStore.socialNetworkUris?.tiktok"
              company="tiktok"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />

            <RSocialButton
              v-if="currentStore.socialNetworkUris?.youtube"
              :link="currentStore.socialNetworkUris?.youtube"
              company="youtube"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />

            <RSocialButton
              v-if="currentStore.socialNetworkUris?.kwai"
              :link="currentStore.socialNetworkUris?.kwai"
              company="kwai"
              class="rounded-lg border-1 border-gray-150 px-[10px] py-1"
            />
          </div>
        </div>
      </div>
    </RBaseCard>

    <RBaseCard v-if="salesperson" class="relative !rounded-2xl !p-4 sm:!p-8">
      <div class="flex flex-col gap-6 text-left">
        <h2 class="text-base font-semibold text-mine-shaft">
          {{ salesperson.name }}
        </h2>

        <div class="flex items-center gap-4">
          <div class="flex flex-1 flex-col gap-2">
            <div
              v-if="globalStore.contactNumber"
              class="flex flex-1 items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div><RIcon size="20" name="mdi:whatsapp" /></div>
              <div>{{ globalStore.contactNumber }}</div>
            </div>

            <div>
              <div class="flex items-center gap-3 text-sm font-medium text-gray-300">
                <div><RIcon size="20" name="mdi:email-outline" /></div>
                <div>{{ salesperson.email || globalStore.store.email }}</div>
              </div>
            </div>

            <div>
              <div class="flex items-center gap-3 text-sm font-medium text-gray-300">
                <div><RIcon size="20" name="material-symbols:link" /></div>
                <div>{{ globalStore.salespersonUrl }}</div>
              </div>
            </div>
          </div>

          <RSalespersonImage
            :salesperson="salesperson"
            class="absolute right-4 top-4 h-12 w-12 sm:static sm:h-16 sm:w-16"
          />
        </div>
      </div>
    </RBaseCard>

    <RBaseCard class="!rounded-2xl !p-4 sm:!p-8">
      <div class="flex flex-col gap-4 text-left">
        <h2 class="text-base font-semibold text-mine-shaft">
          {{ t('components.aboutUs.sectionTwo.title') }}
        </h2>

        <div class="flex flex-col gap-3 sm:gap-6">
          <div class="flex flex-col gap-2">
            <div
              v-if="globalStore.phoneNumber"
              class="flex items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div><RIcon size="20" name="ic:baseline-local-phone" /></div>
              <div>{{ globalStore.phoneNumber }}</div>
            </div>

            <div
              v-if="globalStore.whatsappNumber"
              class="flex items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div><RIcon size="20" name="mdi:whatsapp" /></div>
              <div>{{ globalStore.whatsappNumber }}</div>
            </div>
            <div
              v-if="globalStore.store.taxId"
              class="flex items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div class="text-xl">
                <RTaxId />
              </div>
              <div>{{ globalStore.store.taxId }}</div>
            </div>

            <div v-if="globalStore.store.email" class="flex flex-col gap-2">
              <div class="flex items-center gap-3 text-sm font-medium text-gray-300">
                <div><RIcon size="20" name="mdi:email-outline" /></div>
                <div>{{ globalStore.store.email }}</div>
              </div>
            </div>

            <div
              v-if="globalStore.mapsAddress"
              class="flex items-start gap-3 text-sm font-medium text-gray-300"
            >
              <RIcon class="inline-block" size="20" name="mdi:map-marker-outline" />
              <div class="flex flex-col gap-3">
                {{ globalStore.storeAddress }}
                <RStoreMap class="h-[100px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </RBaseCard>

    <RBaseCard class="!rounded-2xl !p-4 sm:!p-8">
      <div class="flex flex-col gap-4 text-left">
        <h2 class="text-base font-semibold text-mine-shaft">
          {{ t('components.aboutUs.sectionThree.title') }}
        </h2>

        <div v-if="acceptedPayments.length" class="grid grid-cols-1 gap-4 sm:gap-6 md:grid-cols-2">
          <div
            v-for="option in acceptedPayments"
            :key="option.name as string"
            class="flex items-center gap-3 text-[12px] font-medium text-gray-300 sm:text-sm"
          >
            <RIcon size="20" :name="option.icon as string" />

            <span class="text-sm font-medium text-gray-300">
              {{ $t(`globals.paymentMethods.${option.name}`) }}
            </span>
          </div>
        </div>
        <div v-else class="flex items-center gap-3 text-sm font-medium text-gray-300">
          <RIcon size="20" name="ic:outline-handshake" />
          <span style="line-break: anywhere">
            {{ $t('globals.paymentMethods.NOT_SPECIFIED') }}
          </span>
        </div>
      </div>
    </RBaseCard>
    <RBaseCard class="!rounded-2xl !p-4 sm:!p-8">
      <div class="flex flex-col gap-4 text-left">
        <h2 class="text-base font-semibold text-mine-shaft">
          {{ t('components.aboutUs.sectionFour.title') }}
        </h2>

        <div class="flex flex-col gap-3 sm:gap-6">
          <div class="flex flex-col gap-4">
            <div
              v-for="option in options"
              :key="option.value as string"
              class="flex items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div><RIcon size="20" :name="option.icon!" /></div>
              <div>{{ option.label }}</div>
            </div>
            <div
              v-if="currentStore.releaseMethod?.description"
              class="flex items-center gap-3 text-sm font-medium text-gray-300"
            >
              <div>{{ currentStore.releaseMethod?.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </RBaseCard>

    <div class="m-auto flex flex-col gap-3 p-3 font-medium text-gray-300">
      <div class="text-[12px]">
        {{ globalStore.storeAddress }}
      </div>
      <div class="text-center text-[12px]">
        {{ currentStore.name }}
      </div>
    </div>
  </div>
</template>

<style scoped></style>
