<script setup lang="ts">
import { Salesperson } from '@rediredi/types'

const props = defineProps<{
  salesperson: Salesperson
}>()

const names = computed(() => {
  const firstName = props.salesperson.name.split(' ')[0]
  const lastName = props.salesperson.name.split(' ')[1]
  return { firstName, lastName }
})
</script>

<template>
  <img
    v-if="props.salesperson.profileImageUri"
    alt=""
    class="rounded-full border-1 border-solid border-botticelli"
    :src="optimizedImage(props.salesperson.profileImageUri || '/images/empty-logo.png', 'thumb')"
  />
  <div
    v-else
    class="flex items-center justify-center rounded-full border-1 border-solid border-botticelli bg-white font-[sans-serif] text-2xl font-bold uppercase text-aqua-green sm:text-[36px]"
  >
    {{ `${names.firstName?.[0] || ''}${names.lastName?.[0] || ''}` }}
  </div>
</template>
